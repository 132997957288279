import { isElectron } from 'utils/general';
import { toDateInputValue } from 'utils/date-time';

const todayStr = toDateInputValue(new Date());

export const appName = isElectron()
  ? 'admin_panel_electron'
  : 'admin_panel_web';

export const releaseVersion = '3.23.0';

export const version = `${todayStr}-v${releaseVersion}`;
